var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[(_vm.loadingForm)?_c('div',{staticClass:"text-center text-danger my-2",staticStyle:{"padding":"50px 0"}},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading...")])],1):_c('div',[_c('b-card-header',{staticClass:"p-0 mb-1 d-flex align-items-center"},[_c('h3',{staticClass:"text-secondary ml-0"},[_vm._v("Package Details")])]),_c('b-card-body',{staticClass:"p-0"},[_vm._l((_vm.resultPaket),function(item,index){return _c('div',{key:index},[_c('b-row',{staticClass:"relative"},[_c('b-col',{staticClass:"border-bottom mb-1",attrs:{"md":"6"}},[_c('h4',{staticClass:"text-primary ml-0"},[_vm._v("Paket "+_vm._s(index + 1))]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"nama paket","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nama Paket","label-for":"nama"}},[_c('b-form-input',{attrs:{"id":'nama' + index,"placeholder":"Masukkan nama paket","maxlength":"100","readonly":""},model:{value:(item.namapaket),callback:function ($$v) {_vm.$set(item, "namapaket", $$v)},expression:"item.namapaket"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Harga Paket (Rp)","label-for":"harga"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":'harga' + index,"raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan harga paket"},model:{value:(item.harga),callback:function ($$v) {_vm.$set(item, "harga", $$v)},expression:"item.harga"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"harga update","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Harga Upgrade (Rp)","label-for":"hargaupdate"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":'hargaupdate' + index,"raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan harga paket update"},model:{value:(item.hargaupgrade),callback:function ($$v) {_vm.$set(item, "hargaupgrade", $$v)},expression:"item.hargaupgrade"}}),_c('span',{attrs:{"hidden":""}},[_vm._v(_vm._s(item))])],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"hargaPaketUpdate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Harga Paket (Rp)","label-for":"hargaPaketUpdate"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":'hargaPaketUpdate' + index,"value":_vm.calculatePackagePrice(item),"raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan harga paket update"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"jumlah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jumlah (Pax)","label-for":"jumlah"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":'jumlah' + index,"raw":false,"max":"2000","min":"0","options":_vm.options.number,"placeholder":"Masukkan jumlah"},model:{value:(item.jumlah),callback:function ($$v) {_vm.$set(item, "jumlah", $$v)},expression:"item.jumlah"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"total harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Harga (Rp)","label-for":"totalharga"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":'totalharga' + index,"raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan total harga"},model:{value:(item.totalharga),callback:function ($$v) {_vm.$set(item, "totalharga", $$v)},expression:"item.totalharga"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('b-col',{staticClass:"border-bottom mb-1",attrs:{"md":"6"}},[_c('h4',{staticClass:"text-primary ml-0"},[_vm._v("Menu Optional")]),_vm._l((item.orderdetailmenu),function(orderMenu,orderIndex){return _c('div',{key:orderIndex},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{key:orderIndex,attrs:{"name":'nama makanan ' + orderIndex,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((orderMenu.namamenu) + " " + (orderMenu.punyaoptional !== '0' ? '(Ada Pilihan)' : '(Tidak ada pilih)')),"label-for":'namamakanan' + orderIndex,"state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'namamakanan' + orderIndex,"options":_vm.getFilteredNamamakanan(orderMenu.namamenu, item.kodepaket),"label":"text","placeholder":"Pilih"},on:{"input":function($event){return _vm.updateTotalHarga(orderMenu.namamakanan, item)}},model:{value:(orderMenu.namamakanan),callback:function ($$v) {_vm.$set(orderMenu, "namamakanan", $$v)},expression:"orderMenu.namamakanan"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1)})],2),(_vm.resultPaket.length > 1)?_c('b-button',{staticClass:"absolute-top-right",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.handleRemove(item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1)],1)}),_c('b-card-header',{staticClass:"p-0 mb-1 d-flex align-items-center"},[_c('h3',{staticClass:"text-secondary ml-0"},[_vm._v("Payment Details")])]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"total harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Harga (Rp)","label-for":"totalhargaseluruhnya"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"totalhargaseluruhnya","raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan total harga"},model:{value:(_vm.totalHargaSeluruhnya),callback:function ($$v) {_vm.totalHargaSeluruhnya=$$v},expression:"totalHargaSeluruhnya"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Discount","label-for":"discount","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"discount","options":_vm.pilihanDiskonoptionfield,"label":"text","placeholder":"Pilih"},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),(_vm.discount === 'Persen')?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"discount persen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Discount Persen (%)","label-for":"discountPersen"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"discountPersen","raw":false,"max":100,"options":_vm.options.number,"placeholder":"Masukkan jumlah persentase %"},model:{value:(_vm.discountPersen),callback:function ($$v) {_vm.discountPersen=$$v},expression:"discountPersen"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2596447286)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"name":"jumlah discount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jumlah Discount (Rp)","label-for":"nilaidiscountPersen"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"nilaidiscountPersen","raw":false,"max":100,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan jumlah discount"},model:{value:(_vm.nilaidiscountPersen),callback:function ($$v) {_vm.nilaidiscountPersen=$$v},expression:"nilaidiscountPersen"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2153101134)})],1)],1)],1):_vm._e(),(_vm.discount === 'Nominal')?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"potongan harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Potongan Harga (Rp)","label-for":"discountRupiah"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"discountRupiah","raw":false,"max":_vm.totalHargaSeluruhnya,"options":_vm.options.number,"placeholder":"Masukkan potongan harga"},model:{value:(_vm.discountRupiah),callback:function ($$v) {_vm.discountRupiah=$$v},expression:"discountRupiah"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,828508045)})],1):_vm._e()],1)],1),(_vm.discount === 'Nominal' || _vm.discount === 'Persen')?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"total harga setelah discount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Harga Setelah Discount (Rp)","label-for":"totalhargaseluruhnyasetelahdiskon"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"totalhargaseluruhnyasetelahdiskon","raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan total harga seluruhnya setelah discount"},model:{value:(_vm.totalHargaSeluruhnyaSetelahDiscount),callback:function ($$v) {_vm.totalHargaSeluruhnyaSetelahDiscount=$$v},expression:"totalHargaSeluruhnyaSetelahDiscount"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,496819900)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"harga charge","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Charge (Rp)","label-for":"charge"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"charge","raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan harga charge"},model:{value:(_vm.charge),callback:function ($$v) {_vm.charge=$$v},expression:"charge"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"harga ongkir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ongkos Kirim (Rp)","label-for":"ongkir"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"ongkir","raw":false,"options":_vm.options.number,"placeholder":"Masukkan harga ongkir"},model:{value:(_vm.ongkir),callback:function ($$v) {_vm.ongkir=$$v},expression:"ongkir"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"total harga setelah ongkir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Harga Setelah Charge + Ongkir (Rp)","label-for":"totalHargaSeluruhnyaSetelahOngkir"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"totalHargaSeluruhnyaSetelahOngkir","raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan total harga setelah ongkir"},model:{value:(_vm.totalHargaSeluruhnyaSetelahOngkir),callback:function ($$v) {_vm.totalHargaSeluruhnyaSetelahOngkir=$$v},expression:"totalHargaSeluruhnyaSetelahOngkir"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"downpayment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Downpayment (Rp)","label-for":"downpayment"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"downpayment","raw":false,"max":_vm.totalHargaSeluruhnyaSetelahOngkir,"options":_vm.options.number,"placeholder":"Masukkan jumlah downpayment"},model:{value:(_vm.downpayment),callback:function ($$v) {_vm.downpayment=$$v},expression:"downpayment"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"sisa pembayaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sisa Pembayaran (Rp)","label-for":"sisapembayaran"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"sisapembayaran","raw":false,"readonly":"","options":_vm.options.number,"placeholder":"Masukkan jumlah sisa pembayaran"},model:{value:(_vm.sisapembayaran),callback:function ($$v) {_vm.sisapembayaran=$$v},expression:"sisapembayaran"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-row',{staticClass:"m-0"},[_c('b-col',{staticClass:"p-0 d-flex justify-content-between align-items-center",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(("/transaksi/submitcustomer/form/add/" + (_vm.$route.params.id)))}}},[_vm._v(" Add Paket ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_vm._v(" Update ")])],1)],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }