<template>
    <div v-if="dataDetail != null">
        <customer :data="dataDetail" />
        <pesanan :data="dataDetail" />
        <detail-rinci :data="dataDetail" />
    </div>
</template>

<script>
import Customer from './details/DetailCustomer.vue'
import Pesanan from './details/DetailPesanan.vue'
import DetailRinci from './details/DetailRinci.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        Customer,
        Pesanan,
        DetailRinci
    },
    data() {
        return {
            loading: false,
            dataDetail: null,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.$http.get(`/api/transaksi/detail/${this.$route.params.id}`).then(res => {
                this.dataDetail = res.data.data.length > 0 ? res.data.data[0] : null
            }).finally(() => {
                this.loading = false
            }).catch(e => {
                console.log(e)
                this.loading = false
            })
        },
    },
}
</script>