<template>
    <b-card>
        <div class="text-center text-danger my-2" style="padding:50px 0" v-if="loadingForm">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
        </div>

        <div v-else>
            <b-card-header class="p-0 pb-2 d-flex align-items-center">
                <h3 class="text-secondary ml-0">Hasil Record</h3>
            </b-card-header>

            <b-card-body class="p-0">
                <b-row>
                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="status" rules="required">
                            <b-form-group label="Status" label-for="status" :state="errors.length > 0 ? false : null">
                                <v-select id="status" v-model="modelDataHasil.status" :options="statusoptionfield"
                                    label="text" placeholder="Pilih" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="status" rules="required">
                            <b-form-group label="Sudah Ditelpon" label-for="status"
                                :state="errors.length > 0 ? false : null">
                                <b-form-checkbox checked="true" name="check-button" switch inline
                                    v-model="modelDataHasil.cs_sudah_telp">
                                    <span>{{ modelDataHasil.cs_sudah_telp == true ? 'Sudah' :
            modelDataHasil.cs_sudah_telp
                == false ? 'Belum' : '' }}</span>
                                </b-form-checkbox>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <validation-provider #default="{ errors }" name="note" rules="required">
                            <b-form-group label="Keterangan" label-for="note">
                                <b-form-textarea id="note" placeholder="Masukkan keterangan" maxlength="10000" col="24"
                                    rows="4" v-model="modelDataHasil.note" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>
                </b-row>

                <b-row class="m-0">
                    <b-col md="12" class="p-0 d-flex justify-content-between align-items-center">
                        <b-button variant="success" @click.prevent="handleSubmit">
                            Save
                        </b-button>

                        <b-button class="ml-1" variant="dark" @click.prevent="cancelTransaction">
                            Back To List
                        </b-button>

                        <!-- <b-button variant="danger" @click="resetSearch()" class="ml-1">
                            <feather-icon icon="RefreshCcwIcon" />
                        </b-button> -->
                    </b-col>
                </b-row>
            </b-card-body>
        </div>
    </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from 'bootstrap-vue'
import { required } from '@validations'
import moment from 'moment'
import vSelect from 'vue-select'
import ClipboardsSecondary from '@/assets/images/svg/clipboards-secondary.svg'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BButton,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
    },
    data() {
        return {
            ClipboardsSecondary,
            modelDataHasil: {
                status: '',
                note: '',
                cs_sudah_telp: null
            },
            loadingForm: true,
            statusoptionfield: ['Submit by Customer', 'Order Confirm', 'Order Cancel'],
            kodeorder: 0,
            data: {},
            status: 0
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.getDetailData()
        }
    },
    methods: {
        cancelTransaction() {
            let routeName = '';
            if (this.$route.name === 'transaksi/submitcustomer/form') {
                routeName = 'transaksi/submitcustomer';
            } else if (this.$route.name === 'transaksi/orderconfirm/form') {
                routeName = 'transaksi/orderconfirm';
            } else if (this.$route.name === 'transaksi/allorder/form') {
                routeName = 'transaksi/allorder';
            }
            this.$router.push({ name: routeName });
        },

        resetSearch() {
            this.getDetailData();
        },

        getDetailData() {
            this.loadingForm = true
            this.$http.get(`/api/transaksi/detail/${this.$route.params.id}`).then(res => {
                const responseDataPaket = res.data.data[0]
                this.data = { kodeorder: responseDataPaket.kodeorder }
                this.modelDataHasil = {
                    status: parseInt(responseDataPaket.status) == 1 ? 'Submit by Customer' : parseInt(responseDataPaket.status) == 2 ? 'Order Confirm' : parseInt(responseDataPaket.status) == 9 ? 'Order Cancel' : '',
                    cs_sudah_telp: responseDataPaket.cs_sudah_telp == 1 ? true : responseDataPaket.cs_sudah_telp == 0 ? false : null,
                    note: responseDataPaket.cs_note_telp ?? ''
                }

                this.status = parseInt(responseDataPaket.status)

                if (this.status === 3) {
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'SlashIcon',
                            variant: 'danger',
                            text: 'Halaman ini tidak bisa dilihat, karena data sudah dikirim ke ERP, anda akan diarahkan kembali kehalaman sebelumnya!',
                        },
                    });
                    let routeName = '';
                    if (this.$route.name === 'transaksi/submitcustomer/form') {
                        routeName = 'transaksi/submitcustomer';
                    } else if (this.$route.name === 'transaksi/orderconfirm/form') {
                        routeName = 'transaksi/orderconfirm';
                    } else if (this.$route.name === 'transaksi/allorder/form') {
                        routeName = 'transaksi/allorder';
                    }
                    this.$router.push({ name: routeName });
                }

                this.loadingForm = false
            }).catch(e => {
                console.log(e)
                this.loadingForm = false
            })
        },

        handleSubmit() {
            if (!this.validateForm()) {
                return
            }

            const params = {
                kodeorder: this.data.kodeorder,
                status: this.modelDataHasil.status == 'Order Confirm' ? 2 : this.modelDataHasil.status == 'Submit by Customer' ? 1 : this.modelDataHasil.status == 'Pulled by ERP' ? 3 : 9,
                cs_sudah_telp: this.modelDataHasil.cs_sudah_telp == true ? 1 : 0,
                cs_note_telp: this.modelDataHasil.note,
            }

            console.log(JSON.stringify(params))

            this.$http.post('/api/transaksi/editrecordtransaksi', params).then(res => {
                if (!res.data.status) {
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'SlashIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    });
                    this.loadingForm = false;
                    return
                }

                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: '👋Berhasil menyimpan data',
                    },
                });

                if (this.$route.name === 'transaksi/submitcustomer/form') {
                    setTimeout(() => { this.$router.push('/transaksi/submitcustomer') }, 1000)
                } else if (this.$route.name === 'transaksi/orderconfirm/form') {
                    setTimeout(() => { this.$router.push('/transaksi/orderconfirm') }, 1000)
                } else if (this.$route.name === 'transaksi/allorder/form') {
                    setTimeout(() => { this.$router.push('/transaksi/allorder') }, 1000)
                }

            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal menyimpan data' + e,
                    },
                });
            })
        },

        validateForm() {
            if (this.modelDataHasil.keterangan === '' || this.modelDataHasil.keterangan === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan mengisi keterangan',
                    },
                });
                return false;
            }

            if (this.modelDataHasil.status === '' || this.modelDataHasil.status === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan pilih status',
                    },
                });
                return false;
            }

            if (this.modelDataHasil.cs_sudah_telp === '' || this.modelDataHasil.cs_sudah_telp === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan pilih sudah ditelp',
                    },
                });
                return false;
            }
            return true
        }
    },
}
</script>

<style lang="scss" scoped>
.general-info {
    margin-bottom: 8px !important;

    .col {
        align-items: center;
        font-size: 14px;
    }

    .title {
        font-weight: 400;
        color: #64748B;
    }

    .value {
        font-weight: 500;
        color: #334155;
    }
}
</style>
