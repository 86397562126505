<template>
    <b-card>
        <div class="text-center text-danger my-2" style="padding:50px 0" v-if="loadingForm">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
        </div>

        <div v-else>
            <b-card-header class="p-0 mb-1 d-flex align-items-center">
                <h3 class="text-secondary ml-0">Package Details</h3>
            </b-card-header>

            <b-card-body class="p-0">
                <div v-for="(item, index) in resultPaket" :key="index">
                    <b-row class="relative">
                        <b-col md="6" class="border-bottom mb-1">
                            <h4 class="text-primary ml-0">Paket {{ index + 1 }}</h4>
                            <b-row>
                                <b-col md="12">
                                    <validation-provider #default="{ errors }" name="nama paket" rules="required">
                                        <b-form-group label="Nama Paket" label-for="nama">
                                            <b-form-input :id="'nama' + index" placeholder="Masukkan nama paket"
                                                maxlength="100" v-model="item.namapaket" readonly />
                                        </b-form-group>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </validation-provider>
                                </b-col>

                                <b-col md="12">
                                    <validation-provider #default="{ errors }" name="harga" rules="required">
                                        <b-form-group label="Harga Paket (Rp)" label-for="harga">
                                            <cleave :id="'harga' + index" v-model="item.harga" class="form-control"
                                                :raw="false" readonly :options="options.number"
                                                placeholder="Masukkan harga paket" />
                                        </b-form-group>

                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </validation-provider>
                                </b-col>

                                <b-col md="12">
                                    <validation-provider #default="{ errors }" name="harga update" rules="required">
                                        <b-form-group label="Total Harga Upgrade (Rp)" label-for="hargaupdate">
                                            <cleave :id="'hargaupdate' + index" v-model="item.hargaupgrade"
                                                class="form-control" :raw="false" readonly :options="options.number"
                                                placeholder="Masukkan harga paket update" />
                                            <span hidden>{{ item }}</span>
                                        </b-form-group>

                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </validation-provider>
                                </b-col>

                                <b-col md="12">
                                    <validation-provider #default="{ errors }" name="hargaPaketUpdate" rules="required">
                                        <b-form-group label="Total Harga Paket (Rp)" label-for="hargaPaketUpdate">
                                            <cleave :id="'hargaPaketUpdate' + index"
                                                :value="calculatePackagePrice(item)" class="form-control" :raw="false"
                                                readonly :options="options.number"
                                                placeholder="Masukkan harga paket update" />
                                        </b-form-group>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </validation-provider>
                                </b-col>

                                <b-col md="12">
                                    <validation-provider #default="{ errors }" name="jumlah" rules="required">
                                        <b-form-group label="Jumlah (Pax)" label-for="jumlah">
                                            <cleave :id="'jumlah' + index" v-model="item.jumlah" class="form-control"
                                                :raw="false" max="2000" min="0" :options="options.number"
                                                placeholder="Masukkan jumlah" />
                                        </b-form-group>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </validation-provider>
                                </b-col>

                                <b-col md="12">
                                    <validation-provider #default="{ errors }" name="total harga" rules="required">
                                        <b-form-group label="Total Harga (Rp)" label-for="totalharga">
                                            <cleave :id="'totalharga' + index" v-model="item.totalharga"
                                                class="form-control" :raw="false" readonly :options="options.number"
                                                placeholder="Masukkan total harga" />
                                        </b-form-group>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </validation-provider>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col md="6" class="border-bottom mb-1">
                            <h4 class="text-primary ml-0">Menu Optional</h4>
                            <div v-for="(orderMenu, orderIndex) in item.orderdetailmenu" :key="orderIndex">
                                <b-row>
                                    <b-col md="12">
                                        <validation-provider :key="orderIndex" #default="{ errors }"
                                            :name="'nama makanan ' + orderIndex" rules="required">
                                            <b-form-group
                                                :label="`${orderMenu.namamenu} ${orderMenu.punyaoptional !== '0' ? '(Ada Pilihan)' : '(Tidak ada pilih)'}`"
                                                :label-for="'namamakanan' + orderIndex"
                                                :state="errors.length > 0 ? false : null">
                                                <v-select :id="'namamakanan' + orderIndex"
                                                    v-model="orderMenu.namamakanan"
                                                    :options="getFilteredNamamakanan(orderMenu.namamenu, item.kodepaket)"
                                                    label="text" @input="updateTotalHarga(orderMenu.namamakanan, item)"
                                                    placeholder="Pilih" />
                                            </b-form-group>
                                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>

                        <b-button class="absolute-top-right" variant="danger" @click.prevent="handleRemove(item)"
                            size="sm" v-if="resultPaket.length > 1">
                            <feather-icon icon="TrashIcon" />
                            <!-- <span class="d-none d-md-inline">Remove</span> -->
                        </b-button>
                    </b-row>
                </div>

                <b-card-header class="p-0 mb-1 d-flex align-items-center">
                    <h3 class="text-secondary ml-0">Payment Details</h3>
                </b-card-header>

                <b-row>
                    <b-col md="12">
                        <validation-provider #default="{ errors }" name="total harga" rules="required">
                            <b-form-group label="Total Harga (Rp)" label-for="totalhargaseluruhnya">
                                <cleave id="totalhargaseluruhnya" v-model="totalHargaSeluruhnya" class="form-control"
                                    :raw="false" readonly :options="options.number"
                                    placeholder="Masukkan total harga" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <b-row>
                            <b-col md="6">
                                <validation-provider #default="{ errors }" name="discount">
                                    <b-form-group label="Discount" label-for="discount"
                                        :state="errors.length > 0 ? false : null">
                                        <v-select id="discount" v-model="discount" :options="pilihanDiskonoptionfield"
                                            label="text" placeholder="Pilih" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="6" v-if="discount === 'Persen'">
                                <b-row>
                                    <b-col md="4">
                                        <validation-provider #default="{ errors }" name="discount persen"
                                            rules="required">
                                            <b-form-group label="Discount Persen (%)" label-for="discountPersen">
                                                <cleave id="discountPersen" v-model="discountPersen"
                                                    class="form-control" :raw="false" :max="100"
                                                    :options="options.number"
                                                    placeholder="Masukkan jumlah persentase %" />
                                            </b-form-group>
                                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="8">
                                        <validation-provider #default="{ errors }" name="jumlah discount"
                                            rules="required">
                                            <b-form-group label="Jumlah Discount (Rp)" label-for="nilaidiscountPersen">
                                                <cleave id="nilaidiscountPersen" v-model="nilaidiscountPersen"
                                                    class="form-control" :raw="false" :max="100" readonly
                                                    :options="options.number" placeholder="Masukkan jumlah discount" />
                                            </b-form-group>
                                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col md="6" v-if="discount === 'Nominal'">
                                <validation-provider #default="{ errors }" name="potongan harga" rules="required">
                                    <b-form-group label="Potongan Harga (Rp)" label-for="discountRupiah">
                                        <cleave id="discountRupiah" v-model="discountRupiah" class="form-control"
                                            :raw="false" :max="totalHargaSeluruhnya" :options="options.number"
                                            placeholder="Masukkan potongan harga" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col md="12" v-if="discount === 'Nominal' || discount === 'Persen'">
                        <validation-provider #default="{ errors }" name="total harga setelah discount" rules="required">
                            <b-form-group label="Total Harga Setelah Discount (Rp)"
                                label-for="totalhargaseluruhnyasetelahdiskon">
                                <cleave id="totalhargaseluruhnyasetelahdiskon"
                                    v-model="totalHargaSeluruhnyaSetelahDiscount" class="form-control" :raw="false"
                                    readonly :options="options.number"
                                    placeholder="Masukkan total harga seluruhnya setelah discount" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="harga charge" rules="required">
                            <b-form-group label="Charge (Rp)" label-for="charge">
                                <cleave id="charge" v-model="charge" class="form-control" :raw="false" readonly
                                    :options="options.number" placeholder="Masukkan harga charge" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="harga ongkir" rules="required">
                            <b-form-group label="Ongkos Kirim (Rp)" label-for="ongkir">
                                <cleave id="ongkir" v-model="ongkir" class="form-control" :raw="false"
                                    :options="options.number" placeholder="Masukkan harga ongkir" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <validation-provider #default="{ errors }" name="total harga setelah ongkir" rules="required">
                            <b-form-group label="Total Harga Setelah Charge + Ongkir (Rp)"
                                label-for="totalHargaSeluruhnyaSetelahOngkir">
                                <cleave id="totalHargaSeluruhnyaSetelahOngkir"
                                    v-model="totalHargaSeluruhnyaSetelahOngkir" class="form-control" :raw="false"
                                    readonly :options="options.number"
                                    placeholder="Masukkan total harga setelah ongkir" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <validation-provider #default="{ errors }" name="downpayment" rules="required">
                            <b-form-group label="Downpayment (Rp)" label-for="downpayment">
                                <cleave id="downpayment" v-model="downpayment" class="form-control" :raw="false"
                                    :max="totalHargaSeluruhnyaSetelahOngkir" :options="options.number"
                                    placeholder="Masukkan jumlah downpayment" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <validation-provider #default="{ errors }" name="sisa pembayaran" rules="required">
                            <b-form-group label="Sisa Pembayaran (Rp)" label-for="sisapembayaran">
                                <cleave id="sisapembayaran" v-model="sisapembayaran" class="form-control" :raw="false"
                                    readonly :options="options.number" placeholder="Masukkan jumlah sisa pembayaran" />
                            </b-form-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </validation-provider>
                    </b-col>
                </b-row>

                <b-row class="m-0">
                    <b-col md="12" class="p-0 d-flex justify-content-between align-items-center">
                        <b-button variant="primary"
                            @click.prevent="$router.push(`/transaksi/submitcustomer/form/add/${$route.params.id}`)">
                            Add Paket
                        </b-button>

                        <b-button variant="success" @click.prevent="handleSubmit">
                            Update
                        </b-button>

                        <!-- <b-button variant="danger" @click="resetSearch()" class="ml-1">
                            <feather-icon icon="RefreshCcwIcon" />
                        </b-button> -->
                    </b-col>
                </b-row>
            </b-card-body>
        </div>
    </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import { convertToIDR } from '@/@core/utils/utils'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import vSelect from 'vue-select'
import ClipboardsSecondary from '@/assets/images/svg/clipboards-secondary.svg'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
        Cleave,
    },
    data() {
        return {
            ClipboardsSecondary,
            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                }
            },
            modelDataPaket: {
                nama: '',
                harga: '',
                jumlah: '',
                totalhargaupdate: 0,
                total: 0,
                hargaPaket: 0
            },
            loadingForm: true,
            kodeorder: 0,
            data: {},
            resultPaket: [],
            resultMakanan: [],
            resultMakanan1: [],
            resultMenuOptional: [],
            namamenuoptionfield: [],
            namamakananoptionfield: [],
            totalHargaSeluruhnya: 0,
            totalHargaSeluruhnyaAwal: 0,
            totalHargaSeluruhnyaSetelahDiscount: 0,
            totalHargaSeluruhnyaSetelahOngkir: 0,
            charge: 0,
            totalbaru: 0,
            tanggalAcara: '',
            minimalorder: 0,
            menuOptional: [],
            idPaket: [],
            discount: '',
            pilihanDiskonoptionfield: ['Persen', 'Nominal'],
            discountPersen: 0,
            nilaidiscountPersen: 0,
            discountRupiah: 0,
            downpayment: 0,
            sisapembayaran: 0,
            ongkir: 0,
        }
    },
    watch: {
        'resultPaket': {
            handler() {
                this.calculateTotalForEachPackage();
                this.updateTotalPembayaranPersen();
                this.updateTotalPembayaranNominal();
                this.updateSisaPembayaran();
                this.validatePersen();
            },
            deep: true,
        },
        discount: {
            handler(newValue) {
                if (newValue === null) {
                    this.discountPersen = 0;
                    this.nilaidiscountPersen = 0;
                    this.discountRupiah = 0;
                } else {
                    if (newValue === 'Persen') {
                        this.updateTotalPembayaranPersen();
                        this.updateOngkir();
                        this.updateSisaPembayaran();
                    } else {
                        this.updateTotalPembayaranNominal();
                        this.updateOngkir();
                        this.updateSisaPembayaran();
                    }
                }
            },
            deep: true
        },
        discountPersen: {
            handler() {
                this.updateTotalPembayaranPersen();
                this.updateSisaPembayaran();
                this.validatePersen();
            },
            deep: true
        },
        discountRupiah: {
            handler() {
                this.updateTotalPembayaranNominal();
                this.updateSisaPembayaran();
                this.validateNominal();
            },
            deep: true
        },
        nilaidiscountPersen: {
            handler() {
                this.updateTotalPembayaranPersen();
                this.updateSisaPembayaran();
                this.validatePersen();
            },
            deep: true
        },
        downpayment: {
            handler() {
                this.updateSisaPembayaran();
                this.validateDownpayment();
            },
            deep: true
        },
        ongkir: {
            handler() {
                this.updateOngkir();
                this.updateSisaPembayaran();
            },
            deep: true
        },
    },
    mounted() {
        if (this.$route.params.id) {
            this.getDetailData()
        }
    },
    methods: {
        moment,
        convertToIDR,
        validateNumberInput(event) {
            const charCode = event.charCode;
            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            }
        },

        updateTotalHarga(namamakanan, item) {
            const menuTerpilih = item.orderdetailmenu.find(menu => {
                if (typeof menu.namamakanan === 'object') {
                    return menu.namamakanan.text === namamakanan.text;
                } else {
                    return menu.namamakanan === namamakanan.text;
                }
            });

            if (menuTerpilih) {
                menuTerpilih.kodemakanan = menuTerpilih.namamakanan.value;
                menuTerpilih.namamakanan = menuTerpilih.namamakanan.text;
                if (namamakanan.hargaupgrade !== undefined) {
                    menuTerpilih.hargaupgrade = namamakanan.hargaupgrade;
                }
            } else {
                console.log("Menu tidak ditemukan");
            }
        },

        calculateTotalForEachPackage() {
            this.resultPaket.forEach((paket, index) => {
                const quantity = parseInt(paket.jumlah.replace(/,/g, '')) || 0;
                const totalHargaUpgradeMenu = paket.orderdetailmenu.reduce((total, menu) => total + (parseInt(menu.hargaupgrade.replace(/,/g, '')) || 0), 0);
                paket.hargaupgrade = totalHargaUpgradeMenu;
                const hargaPaket = parseInt(paket.harga.replace(/,/g, '')) + totalHargaUpgradeMenu;
                paket.totalharga = hargaPaket * quantity;
                paket.totalPrice = hargaPaket * quantity;
            });
            this.totalHargaSeluruhnya = this.resultPaket.reduce((total, paket) => total + paket.totalPrice, 0);
            this.totalHargaSeluruhnya = parseInt(this.totalHargaSeluruhnya);
        },

        updateTotalPembayaranNominal() {
            const discountRupiahValue = typeof this.discountRupiah === 'string' ? parseInt(this.discountRupiah.replace(/,/g, '')) : 0;
            const ongkirValue = typeof this.ongkir === 'string' ? parseInt(this.ongkir.replace(/,/g, '')) : 0;
            const chargeValue = typeof this.charge === 'string' ? parseInt(this.charge.replace(/,/g, '')) : 0;

            let totalharga = 0
            if (typeof this.totalHargaSeluruhnya === 'string' && this.totalHargaSeluruhnya.includes(',')) {
                totalharga = parseInt(this.totalHargaSeluruhnya.replace(/,/g, ''), 10);
            } else {
                totalharga = parseInt(this.totalHargaSeluruhnya);
            }
            this.totalHargaSeluruhnyaSetelahDiscount = totalharga - discountRupiahValue;
            this.totalHargaSeluruhnyaSetelahOngkir = this.totalHargaSeluruhnyaSetelahDiscount + chargeValue + ongkirValue;
        },

        updateTotalPembayaranPersen() {
            const discountPersenValue = typeof this.discountPersen === 'string' ? parseInt(this.discountPersen.replace(/,/g, '')) : 0;
            const ongkirValue = typeof this.ongkir === 'string' ? parseInt(this.ongkir.replace(/,/g, '')) : 0;
            const chargeValue = typeof this.charge === 'string' ? parseInt(this.charge.replace(/,/g, '')) : 0;

            let totalharga = 0
            if (typeof this.totalHargaSeluruhnya === 'string' && this.totalHargaSeluruhnya.includes(',')) {
                totalharga = parseInt(this.totalHargaSeluruhnya.replace(/,/g, ''), 10);
            } else {
                totalharga = parseInt(this.totalHargaSeluruhnya);
            }

            let totalharga1 = 0
            if (typeof this.nilaidiscountPersen === 'string' && this.nilaidiscountPersen.includes(',')) {
                totalharga1 = parseInt(this.nilaidiscountPersen.replace(/,/g, ''), 10);
            } else {
                totalharga1 = parseInt(this.nilaidiscountPersen);
            }

            this.nilaidiscountPersen = (discountPersenValue / 100) * totalharga;
            this.totalHargaSeluruhnyaSetelahDiscount = totalharga - totalharga1;

            this.totalHargaSeluruhnyaSetelahOngkir = this.totalHargaSeluruhnyaSetelahDiscount + chargeValue + ongkirValue;
        },

        updateOngkir() {
            const ongkirValue = typeof this.ongkir === 'string' ? parseInt(this.ongkir.replace(/,/g, '')) : 0;
            const chargeValue = typeof this.charge === 'string' ? parseInt(this.charge.replace(/,/g, '')) : 0;
            let totalharga = 0
            if (typeof this.totalHargaSeluruhnyaSetelahDiscount === 'string' && this.totalHargaSeluruhnyaSetelahDiscount.includes(',')) {
                totalharga = parseInt(this.totalHargaSeluruhnyaSetelahDiscount.replace(/,/g, ''), 10);
            } else {
                totalharga = parseInt(this.totalHargaSeluruhnyaSetelahDiscount);
            }
            this.totalHargaSeluruhnyaSetelahOngkir = totalharga + chargeValue + ongkirValue;
        },

        updateSisaPembayaran() {
            const downpaymentValue = typeof this.downpayment === 'string' ? parseInt(this.downpayment.replace(/,/g, '')) : 0;
            let totalharga = 0
            if (typeof this.totalHargaSeluruhnyaSetelahOngkir === 'string' && this.totalHargaSeluruhnyaSetelahOngkir.includes(',')) {
                totalharga = parseInt(this.totalHargaSeluruhnyaSetelahOngkir.replace(/,/g, ''), 10);
            } else {
                totalharga = parseInt(this.totalHargaSeluruhnyaSetelahOngkir);
            }
            this.sisapembayaran = totalharga - downpaymentValue;
        },

        validateDownpayment() {
            if (typeof this.downpayment === 'string') {
                const numericDownpayment = parseInt(this.downpayment.replace(/,/g, ''));
                let totalharga = 0
                if (typeof this.totalHargaSeluruhnyaSetelahOngkir === 'string' && this.totalHargaSeluruhnyaSetelahOngkir.includes(',')) {
                    totalharga = parseInt(this.totalHargaSeluruhnyaSetelahOngkir.replace(/,/g, ''), 10);
                } else {
                    totalharga = parseInt(this.totalHargaSeluruhnyaSetelahOngkir);
                }

                if (numericDownpayment > totalharga) {
                    this.downpayment = totalharga;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Warning',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            text: 'Downpayment tidak boleh melebihi total harga setelah charge + ongkir!',
                        },
                    });
                }
            }
        },

        validatePersen() {
            if (typeof this.discountPersen === 'string') {
                const numericdiscountPersen = parseInt(this.discountPersen.replace(/,/g, ''));
                if (numericdiscountPersen > 100) {
                    this.discountPersen = 100;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Warning',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            text: 'Persen discount tidak boleh melebihi 100%!',
                        },
                    });
                }
            }
        },

        validateNominal() {
            if (typeof this.discountRupiah === 'string') {
                const numericdiscountRupiah = parseInt(this.discountRupiah.replace(/,/g, ''));

                let totalharga = 0
                if (typeof this.totalHargaSeluruhnya === 'string' && this.totalHargaSeluruhnya.includes(',')) {
                    totalharga = parseInt(this.totalHargaSeluruhnya.replace(/,/g, ''), 10);
                } else {
                    totalharga = parseInt(this.totalHargaSeluruhnya);
                }

                if (numericdiscountRupiah > totalharga) {
                    this.discountRupiah = totalharga;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Warning',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            text: 'Nominal discount tidak boleh melebihi total harga seluruhnya!',
                        },
                    });
                }
            }
        },

        calculatePackagePrice(item) {
            const totalHargaUpgradeMenu = item.orderdetailmenu.reduce((total, menu) => total + (parseInt(menu.hargaupgrade.replace(/,/g, '')) || 0), 0);
            const hargaPaket = parseInt(item.harga.replace(/,/g, '')) + totalHargaUpgradeMenu;
            return hargaPaket;
        },

        resetSearch() {
            this.getDetailData();
        },

        getDetailData() {
            this.loadingForm = true
            this.$http.get(`/api/transaksi/detail/${this.$route.params.id}`).then(res => {
                const responseDataPaket = res.data.data[0]['orderdetail']
                this.resultPaket = responseDataPaket
                this.resultMenuOptional = this.resultPaket[0]['orderdetailmenu'];
                this.data = { kodeorder: responseDataPaket.kodeorder }
                this.modelDataPaket = {
                    nama: responseDataPaket.nama ?? '',
                    harga: responseDataPaket.harga ?? '-',
                    jumlah: responseDataPaket.jumlah ?? '',
                };
                this.tanggalAcara = res.data.data[0]['tanggalacara']
                this.totalHargaSeluruhnyaAwal = parseInt(responseDataPaket.totalharga)
                this.charge = res.data.data[0]['charge']
                this.downpayment = res.data.data[0]['downpayment']
                this.ongkir = res.data.data[0]['ongkir']

                this.discount =
                    res.data.data[0]['persen'] == '0' && res.data.data[0]['discount'] != 0 ? 'Nominal' :
                        res.data.data[0]['persen'] != '0' && res.data.data[0]['discount'] != 0 ? 'Persen' :
                        res.data.data[0]['persen'] == '0' && res.data.data[0]['discount'] == 0 ? '' : ''

                this.discountPersen = res.data.data[0]['persen']
                this.nilaidiscountPersen = res.data.data[0]['persen'] != '0' ? res.data.data[0]['discount'] : 0
                this.discountRupiah = res.data.data[0]['persen'] == '0' ? res.data.data[0]['discount'] : 0

                this.$http.get(`/api/menu/listmenupaketlanding`).then(res => {
                    const response = res.data.data;
                    this.resultMakanan = response
                }).catch(e => {
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'SlashIcon',
                            variant: 'danger',
                            text: 'Gagal mendapatkan data' + e,
                        },
                    });
                });

                this.$http.get(`/api/makanan/list`).then(res => {
                    const response = res.data.data;
                    this.resultMakanan1 = response
                }).catch(e => {
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'SlashIcon',
                            variant: 'danger',
                            text: 'Gagal mendapatkan data' + e,
                        },
                    });
                });

                this.loadingForm = false
            }).catch(e => {
                console.log(e)
                this.loadingForm = false
            })
        },

        getFilteredNamamakanan(namamenu, kodepaket) {
            const dataMenu = this.resultMakanan;
            const kodePaketTerpilih = kodepaket;

            const menuTerpilih = dataMenu.find(menu => menu.kodepaket === kodePaketTerpilih);
            if (menuTerpilih) {
                const menu = menuTerpilih.menu.find(menu => menu.namamenu === namamenu);
                if (menu && menu.menuoptional) {
                    const filteredOptionalNamamakanan = menu.menuoptional.map(item => ({
                        text: item.namamakanan,
                        value: item.kodemakanan,
                        hargaupgrade: item.hargaupgrade
                    }));
                    return filteredOptionalNamamakanan;
                }
            } else {
                return [];
            }
        },

        getTotalJumlah() {
            return this.resultPaket.reduce((total, paket) => {
                return total + parseInt(paket.jumlah.replace(/,/g, '') || 0);
            }, 0);
        },

        async handleSubmit() {
            try {
                const response = await this.$http.get(`/api/event/list`);
                const eventData = response.data.data;
                const tanggalAcara = this.tanggalAcara;
                let eventFound = false;

                eventData.forEach((item) => {
                    const tgl_awal = new Date(item.tgl_awal);
                    const tgl_akhir = new Date(item.tgl_akhir);
                    const tglAcara = new Date(tanggalAcara);

                    if (tglAcara >= tgl_awal && tglAcara <= tgl_akhir) {
                        this.minimalorder = parseInt(item.minimalorder);
                        eventFound = true;
                    }
                });

                if (!eventFound) {
                    this.minimalorder = 0;
                }

                this.checkSeat()
            } catch (error) {
                console.log(JSON.stringify(error));
            }

            const hasMinQuantity = this.resultPaket.some(order => order.jumlah >= 20);
            if (!hasMinQuantity) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'InfoIcon',
                        variant: 'warning',
                        text: 'Salah satu paket anda belum memiliki minimal pesanan (min: 20pax).',
                    },
                });
            } else {
                const hasZeroQuantity = this.resultPaket.some(order => parseInt(order.jumlah) === 0);
                if (hasZeroQuantity) {
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'InfoIcon',
                            variant: 'warning',
                            text: 'Salah satu paket memiliki jumlah 0. Harap isi semua jumlah sebelum melakukan update.',
                        },
                    });
                } else {
                    const isChargeNonZero = parseInt(this.charge) != 0
                    const isTotalPaxLessThan100 = this.getTotalJumlah() < this.minimalorder;

                    if (isChargeNonZero && isTotalPaxLessThan100) {
                        this.$toast({
                            component: ToastificationContent, position: 'top-right',
                            props: {
                                title: 'Notification',
                                icon: 'InfoIcon',
                                variant: 'warning',
                                text: `Total pax kurang dari ${this.minimalorder} Pax, minimal order adalah ${this.minimalorder} Pax`,
                            },
                        });
                    } else {
                        // this.getDetailData()
                        if (!this.validateForm()) {
                            return
                        }

                        this.loadingForm = true;
                        let sisabayar = 0

                        if (typeof this.sisapembayaran === 'string' && this.sisapembayaran.includes(',')) {
                            sisabayar = parseInt(this.sisapembayaran.replace(/,/g, ''), 10);
                        } else {
                            sisabayar = parseInt(this.sisapembayaran);
                        }

                        if (sisabayar < 0) {
                            this.$toast({
                                component: ToastificationContent, position: 'top-right',
                                props: {
                                    title: 'Notification',
                                    icon: 'InfoIcon',
                                    variant: 'warning',
                                    text: 'Sisa pembayaran tidak valid.',
                                },
                            });
                            this.loadingForm = false;
                            return;
                        } else {
                            const params = {
                                kodeorder: parseInt(this.$route.params.id),
                                totalharga: 0,
                                charge: parseInt(this.charge.replace(/,/g, '')),

                                discount:
                                    this.discount === 'Persen' ? parseInt(this.nilaidiscountPersen) :
                                        this.discount === 'Nominal' ? parseInt(this.discountRupiah.replace(/,/g, '')) : 0,

                                downpayment: parseInt(this.downpayment.replace(/,/g, '')),
                                ongkir: parseInt(this.ongkir.replace(/,/g, '')),

                                persen: this.discount === 'Persen' ? parseInt(this.discountPersen.replace(/,/g, '')) : this.discount === 'Nominal' ? 0 : 0,

                                orderdetail: this.resultPaket.map((paket, index) => {
                                    const totalHargaUpgradeMenu = paket.orderdetailmenu.reduce((total, detail) => total + (detail.totalHargaUpgrade || 0), 0);
                                    const hargaTotal = parseInt(paket.harga.replace(/,/g, '')) + totalHargaUpgradeMenu;

                                    return {
                                        kodeorderdetail: parseInt(paket.kodeorderdetail),
                                        kodepaket: parseInt(paket.kodepaket),
                                        jumlah: parseInt(paket.jumlah.replace(/,/g, '')),
                                        harga: parseInt(paket.harga.replace(/,/g, '')),
                                        hargaupgrade: paket.hargaupgrade,
                                        totalharga: paket.totalPrice,
                                        orderdetailmenu: paket.orderdetailmenu.map(detail => ({
                                            kodeorderdetailmenu: parseInt(detail.kodeorderdetailmenu),
                                            punyaoptional: parseInt(detail.punyaoptional),
                                            kodemenu: parseInt(detail.kodemenu),
                                            kodemakanan: detail.namamakanan.text ? parseInt(detail.namamakanan.value) : parseInt(detail.kodemakanan),
                                            hargaupgrade: detail.totalHargaUpgrade !== undefined && detail.totalHargaUpgrade !== null ? detail.totalHargaUpgrade : parseInt(detail.hargaupgrade) || 0
                                        })),
                                    };
                                }),
                            };

                            if (typeof this.totalHargaSeluruhnya === 'string' && this.totalHargaSeluruhnya.includes(',')) {
                                params.totalharga = parseInt(this.totalHargaSeluruhnya.replace(/,/g, ''), 10);
                            } else {
                                params.totalharga = parseInt(this.totalHargaSeluruhnya);
                            }

                            console.log(JSON.stringify(params))

                            this.$http.post('/api/transaksi/editpaket', params).then(res => {
                                if (!res.data.status) {
                                    this.$toast({
                                        component: ToastificationContent, position: 'top-right',
                                        props: {
                                            title: 'Notification',
                                            icon: 'SlashIcon',
                                            variant: 'danger',
                                            text: res.data.message,
                                        },
                                    });
                                    this.loadingForm = false;
                                    return
                                }

                                this.$toast({
                                    component: ToastificationContent, position: 'top-right',
                                    props: {
                                        title: 'Notification',
                                        icon: 'CheckIcon',
                                        variant: 'success',
                                        text: '👋Berhasil menyimpan data',
                                    },
                                });
                                this.loadingForm = false;
                                // this.resetSearch()
                                window.location.reload()

                            }).catch(e => {
                                this.loadingForm = false;
                                this.$toast({
                                    component: ToastificationContent, position: 'top-right',
                                    props: {
                                        title: 'Notification',
                                        icon: 'SlashIcon',
                                        variant: 'danger',
                                        text: 'Gagal menyimpan data' + e,
                                    },
                                });
                            })
                        }
                    }
                }
            }
        },

        handleRemove(item) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons.fire({
                title: `Apakah anda ingin menghapus paket ${item.namapaket} ini?`,
                text: "Data yang telah dihapus tidak bisa dikembalikan!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    const params = {
                        kodeorder: parseInt(this.$route.params.id),
                        kodeorderdetail: parseInt(item.kodeorderdetail)
                    }

                    this.$http.post('/api/transaksi/paket/remove', params).then(res => {
                        if (!res.data.status) {
                            this.$toast({
                                component: ToastificationContent, position: 'top-right',
                                props: {
                                    title: 'Notification',
                                    icon: 'SlashIcon',
                                    variant: 'danger',
                                    text: res.data.message,
                                },
                            });
                            return
                        }

                        this.$toast({
                            component: ToastificationContent, position: 'top-right',
                            props: {
                                title: 'Notification',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: '👋Berhasil menghapus data',
                            },
                        });
                        this.getDetailData()

                    }).catch(e => {
                        this.$toast({
                            component: ToastificationContent, position: 'top-right',
                            props: {
                                title: 'Notification',
                                icon: 'SlashIcon',
                                variant: 'danger',
                                text: 'Gagal menghapus data' + e,
                            },
                        });
                    })
                }
            });
        },

        validateForm() {
            if (this.resultPaket[0].jumlah === '' || this.resultPaket[0].jumlah === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan masukkan jumlah paket',
                    },
                });
                return false;
            }
            return true
        }
    },
}
</script>

<style lang="scss" scoped>
.general-info {
    margin-bottom: 8px !important;

    .col {
        align-items: center;
        font-size: 14px;
    }

    .title {
        font-weight: 400;
        color: #64748B;
    }

    .value {
        font-weight: 500;
        color: #334155;
    }
}

.relative {
    position: relative;
}

.absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
}
</style>
