<template>
    <b-card>
        <div class="text-center text-danger my-2" style="padding:50px 0" v-if="loadingForm">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
        </div>

        <div v-else>
            <b-card-header class="p-0 pb-2 d-flex align-items-center justify-content-between">
                <h3 class="text-secondary ml-0">Cutomer Data</h3>
                <h2 class="text-info ml-0">
                    Nomor Token: {{ modelDataCustomer.token }}
                    <feather-icon icon="SendIcon" size="24" v-on:click="copyToken" style="cursor: pointer;" title="Send to Whatsapp" />
                </h2>
            </b-card-header>

            <b-card-body class="p-0">
                <b-row>
                    <b-col md="6">
                        <b-row>
                            <b-col md="12" label-cols-md="4">
                                <validation-provider #default="{ errors }" name="nama pemesan" rules="required">
                                    <b-form-group label="Nama Pemesan" label-for="namapemesan">
                                        <b-form-input id="namapemesan" placeholder="Masukkan nama pemesan"
                                            maxlength="100" v-model="modelDataCustomer.namapemesan" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="no hp" rules="required">
                                    <b-form-group label="No. Telp/Whatsapp" label-for="nohp">
                                        <b-form-input id="nohp" placeholder="Masukkan no hp pemesan" type="tel"
                                            pattern="[0-9]*" maxlength="15" minlength="8"
                                            @keypress="validateNumberInput" v-model="modelDataCustomer.nohp" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                                    <b-form-group label="Email" label-for="email">
                                        <b-form-input v-model="modelDataCustomer.email"
                                            :state="errors.length > 0 ? false : null" type="email"
                                            placeholder="Masukkan Email" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="cabang" rules="required">
                                    <b-form-group label="Cabang" label-for="cabang"
                                        :state="errors.length > 0 ? false : null">
                                        <v-select id="cabang" v-model="cabangterpilih" disabled
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="cabangoptionfield" label="text" placeholder="Pilih" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <!-- <b-col md="12">
                                <validation-provider #default="{ errors }" name="sisa seat" rules="required">
                                    <b-form-group label="Jumlah Seat" label-for="seatcabang">
                                        <b-form-input id="seatcabang" placeholder="Masukkan sisa seat" type="tel"
                                            readonly pattern="[0-9]*" @keypress="validateNumberInput"
                                            v-model="seatcabangSisa" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col> -->

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="tanggal order" rules="required">
                                    <b-form-group label="Tanggal Order" label-for="tanggalorder"
                                        :state="errors.length > 0 ? false : null">
                                        <b-form-datepicker local="en" placeholder="Pilih tanggal order" class="mb-1"
                                            :min="new Date()" selected-variant="danger"
                                            v-model="modelDataCustomer.tanggalorder" disabled />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="tanggal acara" rules="required">
                                    <b-form-group label="Tanggal Acara" label-for="tanggalacara"
                                        :state="errors.length > 0 ? false : null">
                                        <b-form-datepicker local="en" placeholder="Pilih tanggal acara" class="mb-1"
                                            :min="tomorrowDate" selected-variant="danger"
                                            :tanggalAcara="modelDataCustomer.tanggalacara"
                                            v-model="modelDataCustomer.tanggalacara" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col md="6">
                        <b-row>
                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="kota acara" rules="required">
                                    <b-form-group label="Kota/Kabupaten" label-for="kotaacara">
                                        <b-form-input id="kotaacara" placeholder="Masukkan kota acara" maxlength="100"
                                            v-model="modelDataCustomer.kotaacara" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="alamat acara" rules="required">
                                    <b-form-group label="Alamat Acara" label-for="alamatacara">
                                        <b-form-textarea id="alamatacara" placeholder="Masukkan alamat acara" col="24"
                                            rows="4" maxlength="100" v-model="modelDataCustomer.alamatacara" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="patokan alamat" rules="required">
                                    <b-form-group label="Patokan Alamat" label-for="patokanalamat">
                                        <b-form-input id="patokanalamat" placeholder="Masukkan patokan alamat"
                                            maxlength="100" v-model="modelDataCustomer.patokanalamat" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="jam cara" rules="required">
                                    <b-form-group label="Jam Acara" label-for="jamacara"
                                        :state="errors.length > 0 ? false : null">
                                        <v-select id="jamacara" v-model="modelDataCustomer.jamacara"
                                            :options="jamacaraoptionfield" label="text" placeholder="Pilih" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="jenis acara" rules="required">
                                    <b-form-group label="Jenis Acara" label-for="jenisacara"
                                        :state="errors.length > 0 ? false : null">
                                        <v-select id="jenisacara" v-model="modelDataCustomer.jenisacara"
                                            :options="jenisacaraoptionfield" label="text" placeholder="Pilih" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>

                            <b-col md="12">
                                <validation-provider #default="{ errors }" name="info teppanku dari" rules="required">
                                    <b-form-group label="Info Teppanku Dari" label-for="infoteppankudari"
                                        :state="errors.length > 0 ? false : null">
                                        <v-select id="infoteppankudari" v-model="modelDataCustomer.infoteppankudari"
                                            :options="infoteppankuoptionfield" selected label="text"
                                            placeholder="Pilih" />
                                    </b-form-group>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row class="m-0">
                    <b-col md="12" class="p-0 d-flex justify-content-end align-items-center">
                        <b-button variant="success" @click.prevent="handleSubmit">
                            Update 
                        </b-button>
                        <!-- <b-button variant="danger" @click="resetSearch()" class="ml-1">
                            <feather-icon icon="RefreshCcwIcon" />
                        </b-button> -->
                    </b-col>
                </b-row>
            </b-card-body>
        </div>
    </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import { convertToIDR } from '@/@core/utils/utils'
import moment from 'moment'
import vSelect from 'vue-select'
import ClipboardsSecondary from '@/assets/images/svg/clipboards-secondary.svg'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
    },
    data() {
        return {
            ClipboardsSecondary,
            modelDataCustomer: {
                token: '',
                namapemesan: '',
                nohp: '',
                email: '',
                alamatacara: '',
                patokanalamat: '',
                kotaacara: '',
                tanggalorder: '',
                tanggalacara: new Date(),
                jamacara: '',
                infoteppankudari: '',
                jenisacara: '',
                cabang: '',
                sisaSeat: '',
                charge: 0,
                chargeAwal: 0,
                minimalorder: 0,
                totalharga: 0,
                totalhargaAwal: 0,
                namaEvent: '',
                seatcabang: 0
            },
            cabangterpilih: '',
            seatcabangSisa: 0,
            loadingForm: true,
            jamacaraoptionfield: [
                '01 Dinihari', '02 Dinihari', '03 Dinihari',
                '04 Pagi', '05 Pagi', '06 Pagi', '07 Pagi', '08 Pagi', '09 Pagi', '10 Pagi',
                '11 Siang', '12 Siang', '01 Siang', '02 Siang',
                '03 Sore', '04 Sore', '05 Sore',
                '06 Petang',
                '07 Malam', '08 Malam', '09 Malam', '10 Malam', '11 Malam', '12 Malam',
            ],
            cabangoptionfield: [],
            infoteppankuoptionfield: [],
            jenisacaraoptionfield: [],
            kodeorder: 0,
            data: {},
        }
    },
    watch: {
        'modelDataCustomer.tanggalacara': 'checkEvent',
        'cabangterpilih': 'checkSeat',
    },
    mounted() {
        this.getListJenisAcara()
        this.getListInfoTeppanku()
        this.getListCabang()
        // this.checkSeat()
        this.checkEvent()
        if (this.$route.params.id) {
            this.getDetailData()
        }
    },
    computed: {
        tomorrowDate() {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            return tomorrow;
        }
    },
    methods: {
        convertToIDR,
        moment,
        validateNumberInput(event) {
            const charCode = event.charCode;
            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            }
        },

        // copyToken() {
        //     const el = document.createElement('textarea');
        //     el.value = this.modelDataCustomer.token;
        //     document.body.appendChild(el);
        //     el.select();
        //     document.execCommand('copy');
        //     document.body.removeChild(el);
        //     this.$toast({
        //         component: ToastificationContent, position: 'top-right',
        //         props: {
        //             title: 'Notification',
        //             icon: 'CheckIcon',
        //             variant: 'success',
        //             text: 'Token berhasil disalin',
        //         },
        //     });
        // },

        copyToken() {
            this.$http.get(`/api/transaksi/detail/${this.$route.params.id}`).then(res => {
                const responseCustomer = res.data.data[0];
                const subtotal = parseInt(responseCustomer.totalharga)
                const discount = parseInt(responseCustomer.discount)
                const persen = parseInt(responseCustomer.persen)
                const totalkurangdiscount = parseInt(responseCustomer.totalharga) - parseInt(responseCustomer.discount)
                const charge = parseInt(responseCustomer.charge)
                const ongkir = parseInt(responseCustomer.ongkir)
                const totalbayar = totalkurangdiscount + charge + ongkir
                const downpayment = parseInt(responseCustomer.downpayment)
                const sisbayarbayar = totalbayar - downpayment

                const phoneNumber = responseCustomer.nohp.replace(/^0/, '62');
                const packageDetails = responseCustomer.orderdetail.map((order, index) => {
                const menus = order.orderdetailmenu.map(menu => menu.namamakanan).join(', ');
                    return {
                        'Paket': index+1,
                        'Nama Paket': order.namapaket,
                        'Harga Paket': parseInt(order.harga).toLocaleString('ID'),
                        'Jumlah': order.jumlah + ' Pax',
                        'Total Upgrade': parseInt(order.hargaupgrade).toLocaleString('ID'),
                        'Total Harga': parseInt(order.totalharga).toLocaleString('ID'),
                        'Menu': menus
                    };
                });
                
                const packageDetailsString = packageDetails.map(detail => {
                    return Object.entries(detail).map(([key, value]) => `${key}: ${value}`).join(',\n    ');}).join('\n\n    ');
                
                const customerDataToCopy = 
                    `Token: ${responseCustomer.token}\n` +
                    `Nama Pemesan: ${responseCustomer.namapemesan}\n` +
                    `No HP: ${responseCustomer.nohp}\n` +
                    `Hari/Tanggal Acara: ${moment(responseCustomer.tanggalacara).format('dddd, DD MMMM YYYY')}\n` +
                    `Jam: ${responseCustomer.jamacara}\n` +
                    `Alamat Acara: ${responseCustomer.alamatacara}\n` +
                    `Kota: ${responseCustomer.kotaacara}\n\n` +
                    `Package Details:\n    ${packageDetailsString}\n\n` +
                    `Payment Details: \n` +
                    `Sub Total Harga: ${subtotal.toLocaleString('ID')}\n` +
                    `Discount ${persen !== 0 ? `(${persen}%)` : ''}: ${discount.toLocaleString('ID')}\n` +
                    `Total Harga - Discount: ${totalkurangdiscount.toLocaleString('ID')}\n` +
                    `Charge: ${charge.toLocaleString('ID')}\n` +
                    `Ongkos Kirim: ${ongkir.toLocaleString('ID')}\n` +
                    `Total Pembayaran: ${totalbayar.toLocaleString('ID')}\n` +
                    `Downpayment: ${downpayment.toLocaleString('ID')}\n` +
                    `Sisa Pembayaran: ${sisbayarbayar.toLocaleString('ID')}\n\n` +
                    `To view your order, you can go to the link https://neworder.teppanku.com/trackingpesanan and enter your token number!\n` +
                    `Thank you for ordering food at teppanku. We are waiting for your next order. Thank You!`;
                    
                const el = document.createElement('textarea');
                el.value = customerDataToCopy;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: 'Detail pesanan berhasil dikirim',
                    },
                });
                const whatsappMessage = encodeURIComponent(customerDataToCopy); 
                window.location.href = `whatsapp://send?phone=${phoneNumber}&text=${whatsappMessage}`;
            }).catch(e => {
                console.log(e);
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Detail pesanan gagal dikirim',
                    },
                });
            });
        },

        resetSearch() {
            this.getDetailData();
        },

        async getDetailData() {
            this.loadingForm = true;
            this.$http.get(`/api/transaksi/detail/${this.$route.params.id}`).then(res => {
                const responseCustomer = res.data.data[0];
                this.data = { kodeorder: responseCustomer.kodeorder };
                this.modelDataCustomer = {
                    token: responseCustomer.token ?? '',
                    namapemesan: responseCustomer.namapemesan ?? '',
                    nohp: responseCustomer.nohp ?? '-',
                    email: responseCustomer.email ?? '-',
                    alamatacara: responseCustomer.alamatacara ?? '',
                    patokanalamat: responseCustomer.patokanalamat ?? '',
                    kotaacara: responseCustomer.kotaacara ?? '',
                    tanggalorder: responseCustomer.tanggalorder ?? '',
                    tanggalacara: responseCustomer.tanggalacara ?? '',
                    jamacara: responseCustomer.jamacara ?? '',
                    chargeAwal: responseCustomer.charge ?? '',
                    totalhargaAwal: responseCustomer.totalharga ?? 0,
                };
                this.modelDataCustomer.cabang = { value: responseCustomer.cabang, text: responseCustomer.cabang, selected: true };
                this.cabangterpilih = this.modelDataCustomer.cabang.value
                this.modelDataCustomer.infoteppankudari = { value: responseCustomer.infoteppankudari, text: responseCustomer.infoteppankudari, selected: true };
                this.modelDataCustomer.jenisacara = { value: responseCustomer.jenisacara, text: responseCustomer.jenisacara, selected: true };
                this.loadingForm = false;
            }).catch(e => {
                console.log(e);
                this.loadingForm = false;
            });
        },

        async checkSeat() {
            try {
                const responseCabang = await this.$http.get('/api/cabang/list');
                const dataCabang = responseCabang.data.data;
                const cabangpilih = this.cabangterpilih.value ?? this.modelDataCustomer.cabang.value
                const selectedCabang = dataCabang.find(item => item.namacabang === cabangpilih);
                if (!selectedCabang) {
                    console.error("Cabang tidak ditemukan:", cabangpilih);
                    return;
                }
                const seatDefault = parseInt(selectedCabang.kapasitas_seat);
                const responseTransaksi = await this.$http.get(`/api/transaksi/count/cabang/${cabangpilih}/tglacara/${this.modelDataCustomer.tanggalacara}`);
                const dataTransaksi = responseTransaksi.data.data;
                const seatTerpakai = dataTransaksi.count;

                this.seatcabangSisa = seatDefault - seatTerpakai;
            } catch (error) {
                console.error("Terjadi kesalahan saat memeriksa seat:", error);
            }
        },

        async checkEvent() {
            try {
                const response = await this.$http.get(`/api/event/list`);
                const eventData = response.data.data;
                const tanggalAcara = this.modelDataCustomer.tanggalacara;
                let eventFound = false;

                eventData.forEach((item) => {
                    const tgl_awal = new Date(item.tgl_awal);
                    const tgl_akhir = new Date(item.tgl_akhir);
                    const tglAcara = new Date(tanggalAcara);
                    const name = item.keterangan;

                    if (tglAcara >= tgl_awal && tglAcara <= tgl_akhir) {
                        this.modelDataCustomer.charge = parseInt(item.charge);
                        this.modelDataCustomer.minimalorder = parseInt(item.minimalorder);
                        this.modelDataCustomer.namaEvent = name;
                        eventFound = true;
                        this.$toast({
                            component: ToastificationContent, position: 'top-right',
                            props: {
                                title: 'Notification',
                                icon: 'InfoIcon',
                                variant: 'info',
                                text: `${this.modelDataCustomer.namaEvent}, mungkin ada biaya tambahan sebesar Rp ${parseInt(item.charge).toLocaleString('ID')}`,
                            },
                        });
                    }
                });

                if (!eventFound) {
                    this.modelDataCustomer.charge = 0;
                    this.modelDataCustomer.minimalorder = 0;
                    this.modelDataCustomer.namaEvent = '';
                }

                this.checkSeat()
            } catch (error) {
                console.log(JSON.stringify(error));
            }
        },

        async getListJenisAcara() {
            const response = await this.$http.get('/api/jenisacara/list')
            response.data.data.forEach(reason => {
                const jenisacaraOption = { text: reason.jenisacara, value: reason.jenisacara };
                if (!this.jenisacaraoptionfield.some(opt => opt.value === jenisacaraOption.value)) {
                    this.jenisacaraoptionfield.push(jenisacaraOption);
                }
            });
            this.loadingForm = false
        },

        async getListInfoTeppanku() {
            this.loadingForm = true
            const response = await this.$http.get('/api/infoteppankudari/list')
            response.data.data.forEach(reason => {
                const infoOption = { text: reason.name, value: reason.name };
                if (!this.infoteppankuoptionfield.some(opt => opt.value === infoOption.value)) {
                    this.infoteppankuoptionfield.push(infoOption);
                }
            });
            this.loadingForm = false
        },

        async getListCabang() {
            const response = await this.$http.get('/api/cabang/list')
            response.data.data.forEach(reason => {
                const cabang = { text: reason.namacabang, value: reason.namacabang };
                this.cabangoptionfield.push(cabang);
            });
        },

        handleSubmit() {
            if (!this.validateForm()) {
                return
            }
            this.loadingForm = true;
            if (this.seatcabangSisa > 0) {
                const params = {
                    kodeorder: this.data.kodeorder,
                    namapemesan: this.modelDataCustomer.namapemesan,
                    nohp: this.modelDataCustomer.nohp,
                    email: this.modelDataCustomer.email,
                    alamatacara: this.modelDataCustomer.alamatacara,
                    patokanalamat: this.modelDataCustomer.patokanalamat,
                    kotaacara: this.modelDataCustomer.kotaacara,
                    tanggalorder: this.modelDataCustomer.tanggalorder,
                    tanggalacara: this.modelDataCustomer.tanggalacara,
                    jamacara: this.modelDataCustomer.jamacara,
                    infoteppankudari: this.modelDataCustomer.infoteppankudari.value,
                    jenisacara: this.modelDataCustomer.jenisacara.value,
                    cabang: this.cabangterpilih.value ?? this.modelDataCustomer.cabang.value,
                    charge:
                        parseInt(this.modelDataCustomer.chargeAwal) === 0 && this.modelDataCustomer.charge > 0 ? parseInt(this.modelDataCustomer.charge) :
                            parseInt(this.modelDataCustomer.chargeAwal) > 0 && this.modelDataCustomer.charge === 0 ? 0 :
                                parseInt(this.modelDataCustomer.chargeAwal) === this.modelDataCustomer.charge ? parseInt(this.modelDataCustomer.chargeAwal) : parseInt(this.modelDataCustomer.chargeAwal),
                    totalharga: parseInt(this.modelDataCustomer.totalhargaAwal)
                }

                console.log(JSON.stringify(params))

                this.$http.post('/api/transaksi/editcustomer', params).then(res => {
                    if (!res.data.status) {
                        this.$toast({
                            component: ToastificationContent, position: 'top-right',
                            props: {
                                title: 'Notification',
                                icon: 'SlashIcon',
                                variant: 'danger',
                                text: res.data.message,
                            },
                        });
                        this.loadingForm = false;
                        return
                    }

                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: '👋Berhasil menyimpan data',
                        },
                    });
                    this.loadingForm = false;
                    // this.resetSearch()
                    window.location.reload()
                }).catch(e => {
                    this.loadingForm = false;
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'SlashIcon',
                            variant: 'danger',
                            text: 'Gagal menyimpan data' + e,
                        },
                    });
                })
            } else {
                this.loadingForm = false;
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'InfoIcon',
                        variant: 'info',
                        text: 'Jumlah seat tidak tersedia',
                    },
                });
            }
            this.checkSeat()
        },

        validateForm() {
            if (this.modelDataCustomer.namapemesan === '' || this.modelDataCustomer.namapemesan === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan mengisi nama lengkap',
                    },
                });
                return false;
            }
            if (!this.modelDataCustomer.namapemesan || !/^[A-Za-z\s]+$/.test(this.modelDataCustomer.namapemesan)) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Nama lengkap hanya mengandung huruf',
                    },
                });
                return false;
            }

            if (this.modelDataCustomer.nohp === '' || this.modelDataCustomer.nohp === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan mengisi nomor hp',
                    },
                });
                return false;
            }
            if (this.modelDataCustomer.nohp.length < 8 || this.modelDataCustomer.nohp.length > 15) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Nomor HP minimal 8 digit dan maksimal 15 digit angka',
                    },
                });
                return false;
            }

            if (this.modelDataCustomer.alamatacara === '' || this.modelDataCustomer.alamatacara === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan mengisi alamat acara',
                    },
                });
                return false;
            }

            if (this.modelDataCustomer.kotaacara === '' || this.modelDataCustomer.kotaacara === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan mengisi kota acara',
                    },
                });
                return false;
            }

            if (this.modelDataCustomer.tanggalacara === '' || this.modelDataCustomer.tanggalacara === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan pilih tanggal acara',
                    },
                });
                return false;
            }

            if (this.modelDataCustomer.jamacara === '' || this.modelDataCustomer.jamacara === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan pilih jam acara',
                    },
                });
                return false;
            }
            return true
        }
    },
}
</script>

<style lang="scss" scoped>
.general-info {
    margin-bottom: 8px !important;

    .col {
        align-items: center;
        font-size: 14px;
    }

    .title {
        font-weight: 400;
        color: #64748B;
    }

    .value {
        font-weight: 500;
        color: #334155;
    }
}

@media only screen and (max-width: 767px) {
    .general-info {
        .col {
            flex-direction: column;
        }
    }
}
</style>
